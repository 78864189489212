<template>
  <div v-if="over">
    <div class="email_result" v-if="!result">
      <i class="el-icon-error red"></i>
      <p class="title">抱歉！激活失败</p>
      <p>{{ message }}</p>
    </div>
    <div class="email_result" v-else>
      <i class="el-icon-success green"></i>
      <p class="title">恭喜！激活成功</p>
      <p>{{ message }}</p>
    </div>
  </div>
</template>

<script>
import { getUrlKey, isMobile } from 'utils/common.js'
import { checkUserEmail } from 'api/user/auth/login'

export default {
  data() {
    return {
      over: false,
      result: true,
      message: '',
      email_form: {
        email: getUrlKey("email", window.location.href),
        user_id: this.$route.params.user_id,
        captcha: this.$route.params.captcha
      }
    }
  },
  mounted() {
    const isMobileResult = isMobile()
    if(isMobileResult) {
      window.location.href = `${window.location.protocol}//${window.location.host}/mobile${this.$route.fullPath}`
    }
    this.confirmEmail();
  },
  methods: {
    confirmEmail() {
      let that = this;
      checkUserEmail(this.email_form).then((res) => {
        that.message = res.msg;
        that.result = res.result === 0 ? true : false;
        that.over = true;
      }).catch(error => {

      });
    }
  }
}

</script>

<style lang="scss" scoped>
  .email_result {
    padding-top:120px;
    text-align: center;
    i {
      font-size:48px;
      margin-bottom:40px;
      &.red {
        color:#F51C21;
      }
      &.green {
        color:#67C23A;
      }
    }
    p {
      margin-top:0;
      margin-bottom:30px;
      font-size:16px;
      &.title {
        font-size:24px;
      }
    }
  }
</style>
